import React, { useEffect, useState } from "react";
import SaverZoneSection from "./SaverZoneSection";
import call_apis from "../../services/Apis";

const SaverZone = ({ type }) => {
  const [saveZone, SetSaveZone] = useState({});

  async function fetchSaverZonedata() { 
    const resp = await call_apis.saverZoneSection(type);
    if (resp.status === 200) {
      SetSaveZone(resp.data.data);
    }
  }

  useEffect(() => {
    fetchSaverZonedata();
  }, [type]);

  return (
    <>
      {Object.keys(saveZone).length > 0 ? (
        <SaverZoneSection data={saveZone} type={type} />
      ) : (
        <div style={{ height: "50vh" }}>
          <div className="loading-indicator"></div>
        </div>
      )}
    </>
  );
};

export default SaverZone;

import React from "react";
import { FilterProvider } from "../SearchResult/context/filter-context";
import CategoryPage from "../NavbarCategoryItems/CategoryPage";
import { useSelector } from "react-redux";
import { apiSelector } from "../../store/Api_middelware";
import { useParams } from "react-router-dom";

function NavbarCategoryItems() {
  const { data } = useSelector(apiSelector);
  var { slug } = useParams();
  const breadCrumData =
    data.length > 0 &&
    data.map((each) => each.subcategory.filter((ele) => ele.url === slug));
  const crumData =
    breadCrumData.length > 0 &&
    breadCrumData.filter((ele1) => ele1.length === 1);
  const crumData1 =
    data.length > 0 &&
    data.filter((ele2) => ele2.category_id === crumData[0][0].category_id);
  const crumData2 = [
    {
      slug: crumData1.length > 0 && crumData1[0].category_name,
      link: "categories",
      url: crumData1.length > 0 && crumData1[0].url,
    },
    { slug: slug, link: "products-category" },
  ];

  return (
    <FilterProvider>
      <CategoryPage data={crumData2} />
    </FilterProvider>
  );
}

export default NavbarCategoryItems;

import React from "react";
import Productfilters from "../SearchResult/Productfilters";
import { useFilter } from "../SearchResult/context/filter-context";
import { useParams, useLocation, useSearchParams } from "react-router-dom";

import call_apis from "../../services/Apis";

const BrandCategoryPage = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setDisplayItems } = useFilter();
  var { slug, slug1 } = useParams();
  const { state } = useLocation();

  const getprojectdetails = async () => {
    const response =
      state !== null
        ? slug1 === undefined && Object.keys(state).length > 0
          ? await call_apis.searchBrandData(slug + "/" + slug1, 1)
          : await call_apis.brandData(
            slug,
            1,
            state.category,
            state.subcategory
          )
        : await call_apis.searchBrandData(slug + "/" + slug1, 1);
    setDisplayItems(response.data.data);
  };

  React.useEffect(() => {
    getprojectdetails();
  }, [slug, slug1]);


  React.useEffect(() => {
    var queryObj = {};
    for (const entry of searchParams.entries()) {
      queryObj = Object.fromEntries([...searchParams]);
    }

    var finalInput = Object.entries(queryObj).map(([key, value]) => ({
      title: key,
      value: value.split(",").map((ele) => ele.split("_")[0]),
    }))

    if (finalInput.length <= 0) {
      getprojectdetails();
    }
  }, [searchParams, slug, slug1]);



  return (
    <div>
      <Productfilters data={data} />
    </div>
  );
};

export default BrandCategoryPage;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CategorySection from "./CategorySection";
import CommonBreadCrumb from "../../componets/CommonBreadCrumb";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apiSelector } from "../../store/Api_middelware";

const CategoryPage = () => {
  const { slug } = useParams();

  const { data } = useSelector(apiSelector);
  const [category, SetCategory] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [subCategoryItem, SetSubCategoryItem] = useState({});

  React.useEffect(() => {
    SetCategory({});
  }, [slug]);

  const breadCrumData =
    data.length > 0 && data.filter((each) => each.url === slug);
  const crumData = [
    {
      slug: breadCrumData.length > 0 && breadCrumData[0].category_name,
      link: "categories",
    },
  ];

  const getCategoryPage = () => {
    axios
      .get(`api/getallcategoryItems?cat_url=${slug}`)
      .then((response) => {
        if (Object.keys(response.data.data).length > 0) {
          SetCategory(response.data.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    getCategoryPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <>
      <Container fluid className="maxWidthContainerFluid">
        <CommonBreadCrumb data={crumData} />
      </Container>
      {Object.keys(category).length > 0 ? (
        <CategorySection
          categoryData={category}
          item={subCategoryItem}
          slug={slug}
        />
      ) : (
        <div style={{ height: "50vh" }}>
          <div className="loading-indicator"></div>
        </div>
      )}
    </>
  );
};

export default CategoryPage;
